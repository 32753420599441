import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))}/>
                <Route path={`${APP_PREFIX_PATH}/roles`} component={lazy(() => import(`./roles`))}/>
                <Route path={`${APP_PREFIX_PATH}/partner`} component={lazy(() => import(`./partner`))}/>
                <Route path={`${APP_PREFIX_PATH}/owner`} component={lazy(() => import(`./owner`))}/>
                <Route path={`${APP_PREFIX_PATH}/pets`} component={lazy(() => import(`./pets`))}/>
                <Route path={`${APP_PREFIX_PATH}/breed`} component={lazy(() => import(`./breed`))}/>
                <Route path={`${APP_PREFIX_PATH}/species`} component={lazy(() => import(`./species`))}/>
                <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))}/>
                <Route path={`${APP_PREFIX_PATH}/blog`} component={lazy(() => import(`./blog`))}/>
                <Route path={`${APP_PREFIX_PATH}/category`} component={lazy(() => import(`./category`))}/>
                <Route path={`${APP_PREFIX_PATH}/marketplace`} component={lazy(() => import(`./marketplace`))}/>
                <Route path={`${APP_PREFIX_PATH}/event`} component={lazy(() => import(`./event`))}/>
                <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))}/>
                <Route path={`${APP_PREFIX_PATH}/vendors`} component={lazy(() => import(`./vendors`))}/>
                <Route path={`${APP_PREFIX_PATH}/comment`} component={lazy(() => import(`./comment`))}/>
                <Route path={`${APP_PREFIX_PATH}/quests`} component={lazy(() => import(`./quests`))} />
                <Route path={`${APP_PREFIX_PATH}/levels`} component={lazy(() => import(`./levels`))} />
                <Route path={`${APP_PREFIX_PATH}/karma-levels`} component={lazy(() => import(`./karma-levels`))} />
                <Route path={`${APP_PREFIX_PATH}/props`} component={lazy(() => import(`./props`))} />
                <Route path={`${APP_PREFIX_PATH}/donations`} component={lazy(() => import(`./donations`))} />

                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`}/>
            </Switch>
        </Suspense>
    )
};

export default React.memo(AppViews);
