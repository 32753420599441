import React from "react";

export default function Container(props) {
  return (
    <div
      style={{
        backgroundImage: 'url("/img/new-background.png")',
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {props.children}
    </div>
  );
}
