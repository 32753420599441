import { TeamOutlined, DollarOutlined } from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {AUTH_DETAIL} from "../redux/constants/Auth";

const DashboardIcon = () => <img 
    src='/img/dashboard.png'
    alt="" 
    width={20} 
    height={20} 
    style={{ marginRight: 10 }}
/>;

const PartnerIcon = () => <img 
    src='/img/partner.png'
    alt="" 
    width={20} 
    height={20} 
    style={{ marginRight: 10 }}
/>;

const GuardianIcon = () => <img 
    src='/img/guardian.png'
    alt="" 
    width={20} 
    height={20} 
    style={{ marginRight: 10 }}
/>;

const MarketIcon = () => <img 
    src='/img/market.png'
    alt="" 
    width={20} 
    height={20} 
    style={{ marginRight: 10 }}
/>;

const MastercodeIcon = () => <img 
    src='/img/mastercode.png'
    alt="" 
    width={20} 
    height={20} 
    style={{ marginRight: 10 }}
/>;

const BlogIcon = () => <img 
    src='/img/blog.png'
    alt="" 
    width={20} 
    height={20} 
    style={{ marginRight: 10 }}
/>;

const KarmaIcon = () => <img 
    src='/img/karma.png'
    alt=''
    width={20}
    height={20}
    style={{ marginRight: 10 }}
 />;

const dashBoardNavTree = [{
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: DashboardIcon,
    breadcrumb: false,
    submenu: []
}];

const partnerNavTree = [{
    key: 'partner-management',
    path: `${APP_PREFIX_PATH}/partner`,
    title: 'sidenav.partner.management',
    icon: PartnerIcon,
    breadcrumb: false,
    submenu: []
}];

const ownerNavTree = [{
    key: 'guardian-management',
    title: 'sidenav.guardian.management',
    icon: GuardianIcon,
    breadcrumb: false,
    submenu: [
        { 
            key: 'guardians',
            title: 'sidenav.owner.list', 
            path: `${APP_PREFIX_PATH}/owner` 
        },
        { 
            key: 'comments',
            title: 'sidenav.comment.list', 
            path: `${APP_PREFIX_PATH}/comment` 
        },
        { 
            key: 'pets',
            title: 'sidenav.pets.list', 
            path: `${APP_PREFIX_PATH}/pets` 
        }
    ]
}];

const marketPlaceNavTree = [{
    key: 'market-management',
    title: 'sidenav.market.management',
    icon: MarketIcon,
    breadcrumb: false,
    submenu: [
        { 
            key: 'products',
            title: 'sidenav.marketplace.list', 
            path: `${APP_PREFIX_PATH}/marketplace` 
        },
        { 
            key: 'categories',
            title: 'sidenav.category.list', 
            path: `${APP_PREFIX_PATH}/category` 
        },
        { 
            key: 'order',
            title: 'sidenav.order.list', 
            path: `${APP_PREFIX_PATH}/orders` ,
            count: localStorage.getItem("orders") || 0
        },
        { 
            key: 'vendor-management',
            title: 'sidenav.vendor.management', 
            path: `${APP_PREFIX_PATH}/vendors` 
        },
        { 
            key: 'donation-management',
            title: 'sidenav.donation.management', 
            path: `${APP_PREFIX_PATH}/donations` 
        }
    ]
}];

const masterCodeNavTree = [{
    key: 'mastercode-admin',
    title: 'sidenav.master.admin',
    icon: MastercodeIcon,
    breadcrumb: false,
    submenu: [
        { 
            key: 'species',
            title: 'sidenav.species.list', 
            path: `${APP_PREFIX_PATH}/species` 
        },
        { 
            key: 'breed',
            title: 'sidenav.breed.list', 
            path: `${APP_PREFIX_PATH}/breed` 
        },
        { 
            key: 'roles-management',
            title: 'sidenav.role.management', 
            path: `${APP_PREFIX_PATH}/roles` 
        }
    ]
}];

const karmaNavTree = [{
    key: 'karma',
    path: `${APP_PREFIX_PATH}/karma`,
    title: 'sidenav.karma.props',
    icon: KarmaIcon,
    breadcrumb: false,
    submenu: [
        { 
            key: 'quest-management',
            title: 'sidenav.quests.management', 
            path: `${APP_PREFIX_PATH}/quests` 
        },
        { 
            key: 'level-management',
            title: 'sidenav.level.management', 
            path: `${APP_PREFIX_PATH}/levels` 
        },
        { 
            key: 'karma-level',
            title: 'sidenav.karma.level', 
            path: `${APP_PREFIX_PATH}/karma-levels`
        },
        { 
            key: 'props-management',
            title: 'sidenav.props.management', 
            path: `${APP_PREFIX_PATH}/props` 
        }
    ]
}]

const blogNavTree = [{
    key: 'blog',
    path: `${APP_PREFIX_PATH}/blog`,
    title: 'sidenav.blog.management',
    icon: BlogIcon,
    breadcrumb: false,
    submenu: []
}];

const partnerOwnerNavTree = [{
    key: 'guardian',
    path: `${APP_PREFIX_PATH}/partner/owner`,
    title: 'sidenav.guardian.management',
    icon: GuardianIcon,
    breadcrumb: false,
    submenu: []
}];

const partnerCommissionNavTree = [{
    key: 'commission',
    title: 'sidenav.commission.list',
    icon: DollarOutlined,
    breadcrumb: false,
    submenu: [
        { 
            key: 'paymentNoticePartner',
            title: 'sidenav.paymentnotice.list', 
            path: `${APP_PREFIX_PATH}/partner/paymentnotices`
        },
        {
            key: 'commissionList',
            path: `${APP_PREFIX_PATH}/partner/commission`,
            title: 'sidenav.commission.list',
        }
    ]
}];

let getNavConfig = () => {
    var loginDetail = localStorage.getItem(AUTH_DETAIL);
    var detailData = {};
    if (loginDetail) {
        detailData = JSON.parse(loginDetail);
    }
    if (detailData.roleId === 1) {
        return [
            ...dashBoardNavTree,
            ...partnerNavTree,
            ...ownerNavTree,
            ...marketPlaceNavTree,
            ...masterCodeNavTree,
            ...karmaNavTree,
            ...blogNavTree
        ];
    } else if(detailData.roleId === 2){
        return [
            ...dashBoardNavTree,
            ...partnerOwnerNavTree,
            ...partnerCommissionNavTree
        ];
    }
};

let navigationConfig = getNavConfig();

export default navigationConfig;
